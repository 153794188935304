<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="(item, index) of slides"
        :key="index"
        :caption="item.caption"
        :text="item.text"
        :img-src="item.imgSrc"
        :img-alt="item.imgAlt"
        :img-blank="item.imgSrc === ''"
      />
    </b-carousel>
    <div class="mt-5  mb-3">
      <b-card
      no-body
      class="overflow-hidden mb-3 border-0 bg-light"
      v-for="(item, index) of focuses"
      :key="index"
    >
        <b-row no-gutters>
          <b-col md="6" v-show="item.imgLeft">
            <b-card-img
              :src="item.imgSrc"
              :alt="item.imgAlt"
              :class="item.class"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body :title="item.title">
              <b-card-text>
                {{ item.text }}
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" v-show="!item.imgLeft">
            <b-card-img
              :src="item.imgSrc"
              :alt="item.imgAlt"
              :class="item.class"
            ></b-card-img>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="mx-auto mt-5 mb-3" style="width: 200px; white-space: nowrap;">
      <h2>解决方案</h2>
    </div>
    <div>
      <b-card-group
        deck
        class="mt-3"
        v-for="(row, idx) of solutions"
        :key="idx"
      >
        <b-card
          :title="item.title"
          :img-src="item.imgSrc"
          :img-alt="item.imgAlt"
          :img-top="item.imgTop"
          v-for="(item, index) of row"
          :key="index"
        >
          <b-card-text>
            {{ item.text }}
          </b-card-text>
          <template #footer>
            <router-link class="card-link" :to="item.to">
              <span>{{ item.span }}</span>
            </router-link>
          </template>
        </b-card>
      </b-card-group>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      slides: [
        {
          caption: "TPM",
          text: "全方位促销管理解决方案",
          imgSrc: "https://picsum.photos/1024/480/?image=52",
          imgAlt: "",
        },
        {
          caption: "TPM SaaS",
          text:
            "TPM提供基于云技术的SaaS解决方案，提供稳定的服务，适合各种规模企业使用。",
          imgSrc: "https://picsum.photos/1024/480/?image=54",
          imgAlt: "",
        },
      ],
      solutions: [
        [
          {
            title: "消费品行业解决方案",
            imgSrc: "https://picsum.photos/300/300/?image=41",
            imgAlt: "Image",
            imgTop: true,
            text:
              "深入消费品行业，提供消费品行业的全行业解决方案，包括销售自动化、费用控制、促销管理、经销商管理等，提升消费品行业的业务流程的IT化和智能化进程。",
            to: "/s/1",
            span: "查看详情 ...",
          },
          {
            title: "促销管理解决方案",
            imgSrc: "https://picsum.photos/300/300/?image=41",
            imgAlt: "Image",
            imgTop: true,
            text:
              "对促销活动的全流程管理，从年度预算、计划管理、费用分摊、促销执行监控、费用核销到计划ROI分析以及对未来计划的优化分析，从而提升促销方案的执行效果。",
            to: "/s/2",
            span: "查看详情 ...",
          },
          {
            title: "费用管控解决方案",
            imgSrc: "https://picsum.photos/300/300/?image=41",
            imgAlt: "Image",
            imgTop: true,
            text:
              "对促销费用使用的全程跟踪和分析，包括费用计划、分摊、核销，以及费用的流程管理。",
            to: "/s/3",
            span: "查看详情 ...",
          },
        ],
        [
          {
            title: "主数据解决方案",
            imgSrc: "https://picsum.photos/300/300/?image=41",
            imgAlt: "Image",
            imgTop: true,
            text:
              "提供一种主数据管理的方案，使得企业能够有效地管理存储在不同系统中的数据，创建和维护业务数据的一致性、完整性、相关性和精确性。提供共享的数据中心，使之成为一系列以客户为中心的业务流程和服务，支持数据的多用户管理，以及提供主数据管理和维护的流程能力。",
            to: "/s/4",
            span: "查看详情 ...",
          },
          {
            title: "经销商管理解决方案",
            imgSrc: "https://picsum.photos/300/300/?image=41",
            imgAlt: "Image",
            imgTop: true,
            text:
              "管理与经销商的合作、活动计划跟踪、费用核销跟踪以及产品销量分析等。",
            to: "/s/5",
            span: "查看详情 ...",
          },
          {
            title: "客户管理解决方案",
            imgSrc: "https://picsum.photos/300/300/?image=41",
            imgAlt: "Image",
            imgTop: true,
            text:
              "以客户为中心，管理客户合作计划、促销活动执行跟踪、费用跟踪和核销、以及P&L分析等。",
            to: "/s/6",
            span: "查看详情 ...",
          },
        ],
      ],
      focuses: [
        {
            title: "专注于国内消费品行业",
            imgSrc: "https://picsum.photos/300/300/?image=41",
            imgAlt: "Image",
            class: 'rounded-0 p-4',
            imgLeft: true,
            text:"和国内领先的消费品行业客户建立了长期的战略性合作关系，服务的客户遍及食品、饮料、美妆、日化、服装等领域。 提供专业的解决方案和服务团队，为客户创造价值。",
          },
        {
            title: "闭环费用管理解决方案",
            imgSrc: "https://picsum.photos/300/300/?image=41",
            imgAlt: "Image",
            class: 'rounded-0 p-4',
            imgLeft: false,
            text:"提供消费品行业客户促销计划费用全流程管控和跟踪，包括客户计划、促销计划、费用管理与分配、收货确认、经销商付款管理、促销计划ROI分析等。",
          },
        {
            title: "专业化服务团队",
            imgSrc: "https://picsum.photos/300/300/?image=41",
            imgAlt: "Image",
            class: 'rounded-0 p-4',
            imgLeft: true,
            text:"建立国际标准的客户服务体系，专业化的产品开发和管理团队，不仅保障解决方案的落地，而且提供专业的客服管理，提供不间断的服务响应，确保对系统业务的全面支持。",
          },
      ]
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>
